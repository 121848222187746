<template>
	<div>
		<div style=" text-align: center;">

			<el-upload style="display: inline-block; margin-right: 20px;" :on-progress="showLoading" action="" :http-request="uploadTemp"
				:on-success="uploadFiles" :show-file-list="false" name="image">
				<img  @click="loading = true" :src="'./images/doc.jpg'" style="width:150px; height:150px" />
			</el-upload>
			
		</div>
		
		<div style="text-align: center; color:blue;font-weight: bold;">点击选择文档，注意:<br />只支持Word和PDF文档</div>

		<div style="overflow: hidden;width: 1px;height: 1px;">
			<img :src="'./images/loading.gif'" />
		</div>
		<div class="mask" style="background:rgba(0,0,0,0.5)" v-if="loading">
			<div style=" margin-top:35vh">

			</div>
			<div style=" text-align:center">
				<img :src="'./images/loading.gif'" style="width:100px; height:100px;border-radius:50%" />
				<div class="" style="color:#fff">
					请稍等...
				</div>
			</div>

		</div>



	</div>
</template>

<script>
	export default {
		data() {
			return {
				timer: 0,
				sessionid: "",
				files: [],
				shopInfo: {},
				shopid: 0,
				openid: "o3yoj4xDdRiwlFZOYe7EOCGNm_Qc",
				printOk: "",
				loading: false
			}
		},
		mounted() {
			this.openid = Request("openid")
			this.shopid = Request("shopid")
			
			
		},
		methods: {
			chooseShop() {
				wx.miniProgram.redirectTo({
					url: '/pages/shop/shop'
				})
			},
			showLoading(){
				this.loading = true
			},

			uploadFiles(e) {
				this.loading = false
				
				if(e.src.indexOf(".pdf")<0&&e.src.indexOf(".doc")<0){
					alert("只能上传pdf或者doc文档")
				}else{
					wx.miniProgram.navigateTo({
						url: "/pages/preview/preview?shopid=" + this.shopid + "&name=" + encodeURIComponent(e.fileName) +
							"&url=" + encodeURIComponent(e.src)
					})
				}
			}
			


		}
	}
</script>

<style scoped>
	.mask {
		position: fixed;
		width: 100%;
		height: 100%;
		z-index: 10;
		top: 0;
	}
</style>
